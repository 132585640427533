import { createAsync } from '@solidjs/router';
import { For } from 'solid-js';
import { Title } from '@solidjs/meta';
import { gql } from '../../graphql';
import { formatDateTimeAttribute } from '../../modules/date-formatting';
import { cachedQuery } from '../../graphql/cached-get';
import type { RouteDefinition } from '@solidjs/router';

export default function Activity() {
	const data = createAsync(() => getActivities({}));

	return (
		<div class="flex flex-col gap-8">
			<Title>Activity | My account | Troon</Title>
			<h1 class="text-3xl font-semibold">Activity</h1>

			<ul class="rounded-md border border-neutral p-4 md:p-6">
				<For each={data()?.transactions}>
					{(transaction) => {
						const date = new Date(
							transaction.dayCreated.year,
							transaction.dayCreated.month - 1,
							transaction.dayCreated.day,
						);
						return (
							<li class="flex flex-row items-center justify-between gap-x-8 border-b border-neutral py-4 last:border-b-0">
								<div>
									<span class="block text-lg">{transaction.facilityName}</span>
									<time datetime={formatDateTimeAttribute(date, false)}>{formatDay(date)}</time>
								</div>
								<span class="text-lg font-semibold">+{transaction.points} points</span>
							</li>
						);
					}}
				</For>
			</ul>
		</div>
	);
}

export const route = {
	preload: () => getActivities({}),
} satisfies RouteDefinition;

const activityQuery = gql(`
query activity {
	transactions: userRewardTransactions {
		points
		facilityName
		dayCreated {
			year
			month
			day
		}
		transactionType
	}
}`);

const getActivities = cachedQuery(activityQuery);
const formatDay = new Intl.DateTimeFormat('en-us', { month: 'short', day: 'numeric', year: 'numeric' }).format;
